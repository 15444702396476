<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">添加</el-button>
          <div class="marginLeft10 width200">
            <el-select v-model="searchData.type" clearable>
              <el-option value="" label="请选择"></el-option>
              <el-option :value="1" label="二手房"></el-option>
              <el-option :value="2" label="新房"></el-option>
              <el-option :value="3" label="办公租赁"></el-option>
            </el-select>
          </div>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">重置</el-button>
        </div>
      </template>
      <template slot="state" slot-scope="{ row }">
        <el-switch v-model="row.state" :inactive-value="2" :active-value="1" @change="handleSwitch($event,row.id)"></el-switch>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        v-if="dialogVisible"
        :title="`${form.id ? '编辑' : '新增'}`"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {add, deleteNew, list, update, updateNew, view} from '@/api/house/label'
export default {
  name: "index",
  data(){
    return{
      searchData: {
        type: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'房源标签管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号', // 索引文字
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '标签名称',
            prop: 'name'
          },
          {
            label: '标签类型',
            prop: 'type',
            formatter: function(row, column, cellValue) {
              return cellValue === 1 ? '二手房' : cellValue === 2 ? '新房' : cellValue === 3 ? '办公租赁' : '/'
            }
          },
          {
            label: '启用状态',
            prop: 'state'
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: '',
        type: '',
        name: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '标签类型',
            prop: 'type',
            type: 'select',
            dicData: [
              {
                label: '二手房',
                value: 1
              },{
                label: '新房',
                value: 2
              },{
                label: '办公租赁',
                value: 3
              }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "请选择标签类型",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择标签类型'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '标签名称',
            prop: 'name',
            span: 24,
            maxlength: 4,
            showWordLimit: true,
            rules: [{
              required: true,
              message: "请输入标签名称",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 添加
    handleCreate(){
      // console.log('添加')
      this.dialogVisible = true;
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        type: ''
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 编辑
    async handleEdit(row){
      // console.log('编辑',row)
      const { data } = await view({
        id: row.id
      })
      // console.log(data)
      this.form = data;
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此标签数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功!');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    handleSwitch(e,id){
      // console.log(e,id)
      updateNew({
        id: id,
        state: e
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功')
        }else{
          this.$message.error(res.msg)
        }
        this.onLoad()
      })
    },
    // 提交
    submit(form,done){
      // console.log(form)
      if(form.id){
        update(form).then(res=>{
          if(res.code === 200){
            this.$message.success('操作成功')
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm()
            }, 500);
            this.onLoad()
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }else{
        add(form).then(res=>{
          if(res.code === 200){
            this.$message.success('操作成功')
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm()
            }, 500);
            this.onLoad()
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }
    },
    // 关闭
    handleClose(){
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm()
      }, 500);
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
        type: '',
        name: ''
      }
    }
  }
}
</script>

<style scoped>

</style>